import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import moment from 'moment';
// @ts-ignore
import qs from 'qs';
import {
    Button,
    Checkbox,
    Col,
    Layout,
    Radio,
    Row,
    Spin,
    Divider,
    message,
    Tabs,
    Slider,
    Result,
    Switch,
    Dropdown,
    Icon,
    Menu
} from 'antd';
import styled from 'styled-components';
import { orderBy, find, indexOf } from 'lodash';
// import Head from 'next/head';
import copy from 'copy-to-clipboard';
import {
    selectOrder,
    onSaveStlSettings,
    approveOrderStage
} from '../../redux/actions';
import { OrderComments } from '../../components/Order/OrderComments';
import OrderCardParams from '../../components/Order/OrderCardParams';
import OrderImageGallery from '../../components/Order/OrderImageGallery';
import Shape from '../../components/Order/OrderStlFiber';
import { OrderStlViewSettings } from '../../components/Order/OrderStlViewSettings';
import { GATEWAY_HOST, UI_HOST } from '../../utils/properties';
import { UpperJawIcon } from '../../components/Icon/UpperJawIcon';
import { LowerJawIcon } from '../../components/Icon/LowerJawIcon';
import { OrderGifCard } from '../../components/Order/OrderGifCard';
import { SliceSlider } from '../../components/Order/SliceSlider';
import { CenteredSpinner } from '../../components/Spinner/CenteredSpinner';
import { isEmpty } from '../../utils/common';
import { AlignersInfo } from '../../components/Aligners/AlignersInfo';

const { Content } = Layout;
const { TabPane } = Tabs;

const TabsContainer = styled.div`
    background: #fff;
    border: 1px solid #e8e8e8;
`;

const TabsBody = styled(TabPane)`
    padding: 4px 24px 24px;
`;

const ContentStyled = styled(Content)`
    padding: 16px;
    background: #fff;
    max-width: 900px;
    border-radius: 4px;
`;

const ContentSpinStyled = styled(Content)`
    padding: 16px;
    margin: 128px auto;
    max-width: 900px;
    border-radius: 4px;
`;

const RowStyled = styled(Row)`
    /* margin-bottom: 36px; */
`;

const ParamsTitle = styled.div`
    margin-top: 8px;
    font-weight: 500;
`;

const ContentLayout = styled(Layout)`
    background: #fff;
`;

const ButtonAction = styled(Button)`
    margin-right: 8px;
`;

const ActionPanel = styled.div`
    margin-right: 8px;
`;

const ModelUpperPanel = styled.div`
    display: inline-block;
    vertical-align: top;

    @media (max-width: 1108px) {
        display: none;
    }
`;
const ModelBottomPanel = styled.div`
    display: none;

    @media (max-width: 1108px) {
        display: block;
    }
`;

const ThemeSwitch = styled(Switch)`
    .ant-switch-inner {
        font-size: 16px;
    }
`;

const ModelLowerButon = styled(Button)`
    height: 64px !important;
    display: inline-block !important;
    margin-left: 28px !important;
    vertical-align: top !important;

    @media (max-width: 1108px) {
        display: none !important;
    }
`;

const MaxillaryUpperPanel = styled.div`
    display: inline-block;
    vertical-align: top;
    @media (max-width: 1108px) {
        display: none !important;
    }
`;
const MaxillaryBottomPanel = styled.div`
    display: none;

    @media (max-width: 1108px) {
        display: block;
    }
`;

export const OrderPage = () => {
    const queryClient = useQueryClient();
    const location = useLocation();
    const params = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const {
        id: idFromQuery,
        revision: revisionFromQuery,
        notification: notificationFromQuery,
        tab: tabFromQuery
    } = params;

    let activeTab = '1';
    if (tabFromQuery === 'messages') {
        activeTab = '2';
    }

    const dispatch = useDispatch();
    const order = useSelector((state: any) => state.OrderReducer.selectedOrder);
    const jwt = useSelector((state: any) => state.AuthReducer.token);
    const profile = useSelector((state: any) => state.ProfileReducer.profile);
    const stlSettings = useSelector(
        (state: any) => state.ProfileReducer.profile.stl
    );
    const texts = useSelector((state: any) => state.CommonReducer.texts);
    const [revision, setRevision] = useState(revisionFromQuery);
    const [theme, setTheme] = useState('light');
    const [slicesCompletelyLoaded, setSlicesCompletelyLoaded] = useState(false);
    const [id, setId] = useState(idFromQuery);
    // const [sceneView, setSceneView] = useState([
    //     -Math.PI / 2,
    //     Math.PI,
    //     Math.PI
    // ]);
    const [sceneView, setSceneView] = useState([0, 0, 0]);
    const [sliceIndex, setSliceIndex] = useState(0);
    const [showOverLayer, setShowOverLayer] = useState(false);

    const [shape, setShape] = useState({
        before: true,
        after: false,
        upper: true,
        lower: true,
        maxillaryUpper: undefined,
        maxillaryLower: undefined,
        maxillaryUpperAfter: undefined,
        maxillaryLowerAfter: undefined,
        maxillaryVisible: true,
        maxillaryColor: stlSettings
            ? stlSettings.maxillary_color
                ? stlSettings.maxillary_color
                : '#e8a0a0'
            : '#e8a0a0',
        maxillaryShineness: stlSettings
            ? stlSettings.maxillary_shineness
                ? stlSettings.maxillary_shineness
                : 60
            : 60,
        maxillaryOpacity: stlSettings
            ? stlSettings.maxillary_opacity
                ? stlSettings.maxillary_opacity
                : 100
            : 100,
        // model
        model: undefined,
        modelVisible: true,
        modelColor: stlSettings ? stlSettings.model_color : '#e6bf8b',
        modelAfterColor: stlSettings
            ? stlSettings.model_after_color
                ? stlSettings.model_after_color
                : '#f6f5e8'
            : '#f6f5e8',
        modelShineness: stlSettings ? stlSettings.model_shineness : 15,
        modelAfterShineness: stlSettings
            ? stlSettings.model_after_shineness
                ? stlSettings.model_after_shineness
                : 20
            : 20,
        extra: undefined,
        extraVisible: true,
        extraColor: stlSettings
            ? stlSettings.extra_color
                ? stlSettings.extra_color
                : '#696d6c'
            : '#696d6c',
        extraShineness: stlSettings
            ? stlSettings.model_shineness
                ? stlSettings.model_shineness
                : 100
            : 100,
        extraOpacity: stlSettings
            ? stlSettings.extra_opacity
                ? stlSettings.extra_opacity
                : 100
            : 100,
        minivints: undefined,
        minivintsVisible: true,
        minivintsColor: stlSettings ? stlSettings.minivints_color : '#009a63',
        minivintsShineness: stlSettings ? stlSettings.minivints_shineness : 100,
        tool: undefined,
        toolVisible: true,
        toolColor: stlSettings ? stlSettings.tool_color : '#ffffff',
        toolShineness: stlSettings ? stlSettings.tool_shineness : 55,
        // splint
        splint: undefined,
        splintVisible: true,
        splintColor: stlSettings
            ? stlSettings.splint_color
                ? stlSettings.splint_color
                : '#aeccdc'
            : '#aeccdc',
        splintShineness: stlSettings ? stlSettings.splint_shineness : 100,
        splintOpacity: stlSettings ? stlSettings.splint_opacity : 70,
        // braces
        bracesUpper: undefined,
        bracesLower: undefined,
        bracesVisible: false,
        bracesColor: stlSettings ? stlSettings.braces_color : '#ffffff',
        bracesShineness: stlSettings ? stlSettings.braces_shineness : 50,
        // template
        template: undefined,
        templateVisible: false,
        templateColor: stlSettings ? stlSettings.template_color : '#e6e1d6',
        templateShineness: stlSettings ? stlSettings.template_shineness : 20,
        tray: undefined,
        trayVisible: true,
        trayColor: stlSettings ? stlSettings.tray_color : '#ffffff',
        trayShineness: stlSettings ? stlSettings.tray_shineness : 100,
        trayOpacity: stlSettings ? stlSettings.tray_opacity : 70,
        bordersVisible: false,
        mode: 'tool'
    });
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        const newParams = qs.parse(location.search, {
            ignoreQueryPrefix: true
        });
        setId(newParams.id);
        setRevision(newParams.revision);
    }, [location]);
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (notificationFromQuery && jwt) {
            fetch(`${GATEWAY_HOST}/notifications/${notificationFromQuery}`, {
                method: 'PUT',
                body: JSON.stringify({ read: true }),
                headers: {
                    Authorization: `Bearer ${jwt}`,
                    'Content-Type': 'application/json'
                }
            }).then(() => {
                queryClient.refetchQueries('notificationsUnreadCount');
            });
        }
    }, [notificationFromQuery, jwt]);

    const getUsername = (doctor: any) => {
        // order.doctor.last_name, order.doctor.first_name, order.doctor.middle_name
        let username = doctor.last_name;
        if (!isEmpty(doctor.first_name)) {
            username += ` ${doctor.first_name[0]}.`;
        }

        if (!isEmpty(doctor.middle_name)) {
            username += ` ${doctor.middle_name[0]}.`;
        }
        return username;
    };

    const getStageByState = (currentOrder: any) => {
        let stage = null;
        if (
            indexOf(
                ['NEW', 'ON_APPROVAL', 'CORRECTION'],
                currentOrder.status_minivints
            ) >= 0
        ) {
            stage = 'stage1';
        } else if (
            indexOf(
                ['NEW', 'ON_APPROVAL', 'CORRECTION'],
                currentOrder.status_template
            ) >= 0
        ) {
            stage = 'stage2';
        } else if (
            indexOf(
                ['NEW', 'ON_APPROVAL', 'CORRECTION'],
                currentOrder.status_equipment
            ) >= 0
        ) {
            stage = 'stage3';
        }
        return stage;
    };

    const onCopy = (orderId: any, revision: any, texts: any, order: any) => {
        const doctor = getUsername(order.doctor);
        let msg = `${texts['order.doctor'].value}: ${doctor}; ${texts['order.patient'].value}: ${order.patient};`;
        const stage = getStageByState(order);
        if (stage) {
            msg = `${msg} ${texts['order.stage'].value}: ${
                texts[`order.stage.${stage}`].value
            };`;
        }
        copy(`
            ${msg} ${texts.link.value}: ${UI_HOST}/shared?id=${orderId}&revision=${revision}
        `);
        message.success(texts['share.success'].value);
    };

    // const getTitle = (order: any, texts: any) => {
    //     const doctor = getUsername(order.doctor);
    //     const msg = `${texts['order.doctor'].value}: ${doctor}; ${texts['order.patient'].value}: ${order.patient}`;
    //     return msg;
    // };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        dispatch(selectOrder(id, revision));
    }, [id, revision, dispatch]);

    const onChangeModelUpper = () => {
        const newShape = { ...shape };
        newShape.upper = !newShape.upper;
        setShape(newShape);
    };

    const onChangeModelLower = () => {
        const newShape = { ...shape };
        newShape.lower = !newShape.lower;
        setShape(newShape);
    };

    const onChangeMinivints = (e: any) => {
        const newShape = { ...shape };
        newShape.minivintsVisible = e.target.checked;
        if (newShape.minivintsVisible) {
            newShape.after = false;
            newShape.before = true;
        }
        setShape(newShape);
    };

    const onChangeBefore = (e: any) => {
        const newShape = { ...shape };
        newShape.before = e.target.checked;
        setShape(newShape);
    };
    const onChangeAfter = (e: any) => {
        const newShape = { ...shape };
        newShape.after = e.target.checked;
        if (
            (!newShape.after && order.status_braces !== 'NONE') ||
            order.status_aligners !== 'NONE'
        ) {
            newShape.mode = 'none';
            // newShape.after = false;
            // newShape.before = true;
            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        }
        setShape(newShape);
    };

    const onChangeExtra = (e: any) => {
        const newShape = { ...shape };
        newShape.extraVisible = e.target.checked;
        setShape(newShape);
    };

    const onChangeMode = (e: any) => {
        const newShape = { ...shape };
        const currentOrder = order;
        if (e.target.value === 'tool') {
            newShape.mode = e.target.value;
            newShape.after = false;
            newShape.before = true;
            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = true;
            newShape.trayVisible = true;
            newShape.splintVisible = false;
        } else if (e.target.value === 'splint') {
            newShape.mode = e.target.value;
            newShape.after = false;
            newShape.before = true;
            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = true;
        } else if (e.target.value === 'template') {
            newShape.mode = e.target.value;
            newShape.after = false;
            newShape.before = true;
            newShape.templateVisible = true;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        } else if (e.target.value === 'braces') {
            newShape.mode = e.target.value;
            newShape.after = true;
            newShape.before = false;
            newShape.bracesVisible = true;
            newShape.templateVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        } else {
            newShape.mode = e.target.value;
            // newShape.after = false;
            // newShape.before = true;
            if (
                currentOrder.status_braces !== 'NONE' ||
                currentOrder.status_aligners !== 'NONE'
            ) {
                newShape.after = true;
                newShape.before = false;
            }

            newShape.templateVisible = false;
            newShape.bracesVisible = false;
            newShape.toolVisible = false;
            newShape.trayVisible = false;
            newShape.splintVisible = false;
        }
        setShape(newShape);
    };

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (order) {
            const orderedVersions = orderBy(
                order.orderversions,
                ['design_date'],
                ['desc']
            );
            const orderVersion =
                revision === 'latest'
                    ? orderedVersions[0]
                    : find(orderedVersions, (o) => `${o.id}` === revision);
            // equipment
            const stl3 = isEmpty(orderVersion.stl_3)
                ? ''
                : orderVersion.stl_3.rawUrl;
            // equipment
            const stl4 = isEmpty(orderVersion.stl_4)
                ? ''
                : orderVersion.stl_4.rawUrl;
            // equipment
            const stl18 = isEmpty(orderVersion.stl_18)
                ? ''
                : orderVersion.stl_18.rawUrl;
            // equipment
            const stl19 = isEmpty(orderVersion.stl_19)
                ? ''
                : orderVersion.stl_19.rawUrl;
            // equipment
            const stl20 = isEmpty(orderVersion.stl_20)
                ? ''
                : orderVersion.stl_20.rawUrl;
            let result = 'none';
            if (stl3 !== '') {
                result = 'tool';
            } else if (stl4 !== '') {
                result = 'template';
            } else if (stl18 !== '' || stl19 !== '') {
                result = 'braces';
            } else if (stl20 !== '') {
                result = 'splint';
            }
            onChangeMode({ target: { value: result } });
        }
    }, [order]);

    const onChangeColor = (what: string, color: string) => {
        const newShape = { ...shape };
        if (what === 'model') {
            newShape.modelColor = color;
        } else if (what === 'modelAfter') {
            newShape.modelAfterColor = color;
        } else if (what === 'template') {
            newShape.templateColor = color;
        } else if (what === 'minivints') {
            newShape.minivintsColor = color;
        } else if (what === 'tray') {
            newShape.trayColor = color;
        } else if (what === 'tool') {
            newShape.toolColor = color;
        } else if (what === 'splint') {
            newShape.splintColor = color;
        } else if (what === 'maxillary') {
            newShape.maxillaryColor = color;
        } else if (what === 'extra') {
            newShape.extraColor = color;
        }
        setShape(newShape);
    };

    const onDefault = (
        newModelColor: string,
        newModelAfterColor: string,
        newMinivintsColor: string,
        newTemplateColor: string,
        newToolColor: string,
        newTrayColor: string,
        newSplintColor: string,
        newMaxillaryColor: string,
        newExtraColor: string,
        newModelShineness: number,
        newModelAfterShineness: number,
        newMinivintsShineness: number,
        newTemplateShineness: number,
        newToolShineness: number,
        newTrayShineness: number,
        newTrayOpacity: number,
        newSplintShineness: number,
        newSplintOpacity: number,
        newMaxillaryShineness: number,
        newExtraOpacity: number
    ) => {
        const newShape = { ...shape };
        newShape.modelColor = newModelColor;
        newShape.modelAfterColor = newModelAfterColor;
        newShape.modelShineness = newModelShineness;
        newShape.modelAfterShineness = newModelAfterShineness;
        newShape.minivintsColor = newMinivintsColor;
        newShape.minivintsShineness = newMinivintsShineness;
        newShape.templateColor = newTemplateColor;
        newShape.templateShineness = newTemplateShineness;
        newShape.toolColor = newToolColor;
        newShape.toolShineness = newToolShineness;
        newShape.trayColor = newTrayColor;
        newShape.trayShineness = newTrayShineness;
        newShape.trayOpacity = newTrayOpacity;
        // Splint
        newShape.splintColor = newSplintColor;
        newShape.splintShineness = newSplintShineness;
        newShape.splintOpacity = newSplintOpacity;
        // Maxillary
        newShape.maxillaryColor = newMaxillaryColor;
        newShape.maxillaryShineness = newMaxillaryShineness;
        // Extra
        newShape.extraColor = newExtraColor;
        newShape.extraOpacity = newExtraOpacity;
        setShape(newShape);
    };

    const onChangeShineness = (what: string, shineness: number) => {
        const newShape = { ...shape };
        if (what === 'model') {
            newShape.modelShineness = shineness;
        } else if (what === 'modelAfter') {
            newShape.modelAfterShineness = shineness;
        } else if (what === 'template') {
            newShape.templateShineness = shineness;
        } else if (what === 'minivints') {
            newShape.minivintsShineness = shineness;
        } else if (what === 'tray') {
            newShape.trayShineness = shineness;
        } else if (what === 'tool') {
            newShape.toolShineness = shineness;
        } else if (what === 'splint') {
            newShape.splintShineness = shineness;
        } else if (what === 'maxillary') {
            newShape.maxillaryShineness = shineness;
        }
        setShape(newShape);
    };

    const onChangeOpacity = (what: string, opacity: number) => {
        const newShape = { ...shape };
        if (what === 'model') {
            // newShape.modelOpacity = opacity;
        } else if (what === 'template') {
            // newShape.templateOpacity = opacity;
        } else if (what === 'minivints') {
            // newShape.minivintsOpacity = opacity;
        } else if (what === 'tray') {
            newShape.trayOpacity = opacity;
        } else if (what === 'tool') {
            // newShape.toolOpacity = opacity;
        } else if (what === 'splint') {
            newShape.splintOpacity = opacity;
        } else if (what === 'maxillary') {
            newShape.maxillaryOpacity = opacity;
        } else if (what === 'extra') {
            newShape.extraOpacity = opacity;
        }
        setShape(newShape);
    };

    let content = (
        <ContentLayout>
            <ContentSpinStyled>
                <Spin
                    size="large"
                    style={{ display: 'block', margin: '0 auto' }}
                />
            </ContentSpinStyled>
        </ContentLayout>
    );
    if (order) {
        const orderedVersions = orderBy(
            order.orderversions,
            ['design_date'],
            ['desc']
        );
        const orderVersion =
            revision === 'latest'
                ? orderedVersions[0]
                : find(orderedVersions, (o) => `${o.id}` === revision);
        // template video
        const video = isEmpty(orderVersion.video)
            ? ''
            : orderVersion.video.rawUrl;
        // equipment gif
        const gif = isEmpty(orderVersion.gif) ? '' : orderVersion.gif.rawUrl;
        // equipment gif 2d
        const gif2d = isEmpty(orderVersion.gif2d)
            ? ''
            : orderVersion.gif2d.rawUrl;
        // equipment gif splint
        // eslint-disable-next-line
        const gif_splint = isEmpty(orderVersion.gif_splint)
            ? ''
            : orderVersion.gif_splint.rawUrl;
        const workorder = isEmpty(orderVersion.workorder)
            ? ''
            : orderVersion.workorder.rawUrl;
        const zip = isEmpty(orderVersion.zip) ? '' : orderVersion.zip.rawUrl;
        const { slices } = orderVersion;
        const isSlicesAvailable = !isEmpty(slices);
        // model
        const stl1 = isEmpty(orderVersion.stl_1)
            ? ''
            : orderVersion.stl_1.rawUrl;
        // minivints
        const stl2 = isEmpty(orderVersion.stl_2)
            ? ''
            : orderVersion.stl_2.rawUrl;
        // equipment
        const stl3 = isEmpty(orderVersion.stl_3)
            ? ''
            : orderVersion.stl_3.rawUrl;
        // template
        const stl4 = isEmpty(orderVersion.stl_4)
            ? ''
            : orderVersion.stl_4.rawUrl;
        // tray
        const stl5 = isEmpty(orderVersion.stl_5)
            ? ''
            : orderVersion.stl_5.rawUrl;
        // addition
        const stl6 = isEmpty(orderVersion.stl_6)
            ? ''
            : orderVersion.stl_6.rawUrl;
        // upper jaw model after
        const stl7 = isEmpty(orderVersion.stl_7)
            ? ''
            : orderVersion.stl_7.rawUrl;
        // lower jaw model before
        const stl8 = isEmpty(orderVersion.stl_8)
            ? ''
            : orderVersion.stl_8.rawUrl;
        // lower jaw model after
        const stl9 = isEmpty(orderVersion.stl_9)
            ? ''
            : orderVersion.stl_9.rawUrl;
        // upper maxillary model before
        const stl10 = isEmpty(orderVersion.stl_10)
            ? ''
            : orderVersion.stl_10.rawUrl;
        // upper maxillary model after
        const stl11 = isEmpty(orderVersion.stl_11)
            ? ''
            : orderVersion.stl_11.rawUrl;
        // lower maxillary model before
        const stl12 = isEmpty(orderVersion.stl_12)
            ? ''
            : orderVersion.stl_12.rawUrl;
        // lower maxillary model after
        const stl13 = isEmpty(orderVersion.stl_13)
            ? ''
            : orderVersion.stl_13.rawUrl;
        // upper jaw braces
        const stl18 = isEmpty(orderVersion.stl_18)
            ? ''
            : orderVersion.stl_18.rawUrl;
        // lower jaw braces
        const stl19 = isEmpty(orderVersion.stl_19)
            ? ''
            : orderVersion.stl_19.rawUrl;
        // splint
        const stl20 = isEmpty(orderVersion.stl_20)
            ? ''
            : orderVersion.stl_20.rawUrl;
        const img1 = isEmpty(orderVersion.image_1)
            ? ''
            : orderVersion.image_1.url;
        const img2 = isEmpty(orderVersion.image_2)
            ? ''
            : orderVersion.image_2.url;
        const img3 = isEmpty(orderVersion.image_3)
            ? ''
            : orderVersion.image_3.url;
        const img4 = isEmpty(orderVersion.image_4)
            ? ''
            : orderVersion.image_4.url;
        const img5 = isEmpty(orderVersion.image_5)
            ? ''
            : orderVersion.image_5.url;

        // minivints
        const minivintsImage1 = isEmpty(orderVersion.minivints_image_1)
            ? ''
            : orderVersion.minivints_image_1.url;
        const minivintsImage2 = isEmpty(orderVersion.minivints_image_2)
            ? ''
            : orderVersion.minivints_image_2.url;
        const minivintsImage3 = isEmpty(orderVersion.minivints_image_3)
            ? ''
            : orderVersion.minivints_image_3.url;
        const minivintsImage4 = isEmpty(orderVersion.minivints_image_4)
            ? ''
            : orderVersion.minivints_image_4.url;
        const minivintsImage5 = isEmpty(orderVersion.minivints_image_5)
            ? ''
            : orderVersion.minivints_image_5.url;
        const minivintsImage6 = isEmpty(orderVersion.minivints_image_6)
            ? ''
            : orderVersion.minivints_image_6.url;
        const minivintsImage7 = isEmpty(orderVersion.minivints_image_7)
            ? ''
            : orderVersion.minivints_image_7.url;
        const minivintsImage8 = isEmpty(orderVersion.minivints_image_8)
            ? ''
            : orderVersion.minivints_image_8.url;
        const minivintsImage9 = isEmpty(orderVersion.minivints_image_9)
            ? ''
            : orderVersion.minivints_image_9.url;
        const minivintsImage10 = isEmpty(orderVersion.minivints_image_10)
            ? ''
            : orderVersion.minivints_image_10.url;

        // template
        const templateImage1 = isEmpty(orderVersion.template_image_1)
            ? ''
            : orderVersion.template_image_1.url;
        const templateImage2 = isEmpty(orderVersion.template_image_2)
            ? ''
            : orderVersion.template_image_2.url;
        const templateImage3 = isEmpty(orderVersion.template_image_3)
            ? ''
            : orderVersion.template_image_3.url;
        const templateImage4 = isEmpty(orderVersion.template_image_4)
            ? ''
            : orderVersion.template_image_4.url;
        const templateImage5 = isEmpty(orderVersion.template_image_5)
            ? ''
            : orderVersion.template_image_5.url;
        const templateImage6 = isEmpty(orderVersion.template_image_6)
            ? ''
            : orderVersion.template_image_6.url;
        const templateImage7 = isEmpty(orderVersion.template_image_7)
            ? ''
            : orderVersion.template_image_7.url;
        const templateImage8 = isEmpty(orderVersion.template_image_8)
            ? ''
            : orderVersion.template_image_8.url;
        const templateImage9 = isEmpty(orderVersion.template_image_9)
            ? ''
            : orderVersion.template_image_9.url;
        const templateImage10 = isEmpty(orderVersion.template_image_10)
            ? ''
            : orderVersion.template_image_10.url;

        // tool
        const toolImage1 = isEmpty(orderVersion.tool_image_1)
            ? ''
            : orderVersion.tool_image_1.url;
        const toolImage2 = isEmpty(orderVersion.tool_image_2)
            ? ''
            : orderVersion.tool_image_2.url;
        const toolImage3 = isEmpty(orderVersion.tool_image_3)
            ? ''
            : orderVersion.tool_image_3.url;
        const toolImage4 = isEmpty(orderVersion.tool_image_4)
            ? ''
            : orderVersion.tool_image_4.url;
        const toolImage5 = isEmpty(orderVersion.tool_image_5)
            ? ''
            : orderVersion.tool_image_5.url;
        const toolImage6 = isEmpty(orderVersion.tool_image_6)
            ? ''
            : orderVersion.tool_image_6.url;
        const toolImage7 = isEmpty(orderVersion.tool_image_7)
            ? ''
            : orderVersion.tool_image_7.url;
        const toolImage8 = isEmpty(orderVersion.tool_image_8)
            ? ''
            : orderVersion.tool_image_8.url;
        const toolImage9 = isEmpty(orderVersion.tool_image_9)
            ? ''
            : orderVersion.tool_image_9.url;
        const toolImage10 = isEmpty(orderVersion.tool_image_10)
            ? ''
            : orderVersion.tool_image_10.url;

        // tool 2d
        const tool2dImage1 = isEmpty(orderVersion.tool_2d_image_1)
            ? ''
            : orderVersion.tool_2d_image_1.url;
        const tool2dImage2 = isEmpty(orderVersion.tool_2d_image_2)
            ? ''
            : orderVersion.tool_2d_image_2.url;
        const tool2dImage3 = isEmpty(orderVersion.tool_2d_image_3)
            ? ''
            : orderVersion.tool_2d_image_3.url;
        const tool2dImage4 = isEmpty(orderVersion.tool_2d_image_4)
            ? ''
            : orderVersion.tool_2d_image_4.url;
        const tool2dImage5 = isEmpty(orderVersion.tool_2d_image_5)
            ? ''
            : orderVersion.tool_2d_image_5.url;
        const tool2dImage6 = isEmpty(orderVersion.tool_2d_image_6)
            ? ''
            : orderVersion.tool_2d_image_6.url;
        const tool2dImage7 = isEmpty(orderVersion.tool_2d_image_7)
            ? ''
            : orderVersion.tool_2d_image_7.url;
        const tool2dImage8 = isEmpty(orderVersion.tool_2d_image_8)
            ? ''
            : orderVersion.tool_2d_image_8.url;
        const tool2dImage9 = isEmpty(orderVersion.tool_2d_image_9)
            ? ''
            : orderVersion.tool_2d_image_9.url;
        const tool2dImage10 = isEmpty(orderVersion.tool_2d_image_10)
            ? ''
            : orderVersion.tool_2d_image_10.url;

        // virtual setup
        const vsImage1 = isEmpty(orderVersion.vs_image_1)
            ? ''
            : orderVersion.vs_image_1.url;
        const vsImage2 = isEmpty(orderVersion.vs_image_2)
            ? ''
            : orderVersion.vs_image_2.url;
        const vsImage3 = isEmpty(orderVersion.vs_image_3)
            ? ''
            : orderVersion.vs_image_3.url;
        const vsImage4 = isEmpty(orderVersion.vs_image_4)
            ? ''
            : orderVersion.vs_image_4.url;
        const vsImage5 = isEmpty(orderVersion.vs_image_5)
            ? ''
            : orderVersion.vs_image_5.url;
        const vsImage6 = isEmpty(orderVersion.vs_image_6)
            ? ''
            : orderVersion.vs_image_6.url;
        const vsImage7 = isEmpty(orderVersion.vs_image_7)
            ? ''
            : orderVersion.vs_image_7.url;
        const vsImage8 = isEmpty(orderVersion.vs_image_8)
            ? ''
            : orderVersion.vs_image_8.url;
        const vsImage9 = isEmpty(orderVersion.vs_image_9)
            ? ''
            : orderVersion.vs_image_9.url;
        const vsImage10 = isEmpty(orderVersion.vs_image_10)
            ? ''
            : orderVersion.vs_image_10.url;
        const vsImage11 = isEmpty(orderVersion.vs_image_11)
            ? ''
            : orderVersion.vs_image_11.url;
        const vsImage12 = isEmpty(orderVersion.vs_image_12)
            ? ''
            : orderVersion.vs_image_12.url;
        const vsImage13 = isEmpty(orderVersion.vs_image_13)
            ? ''
            : orderVersion.vs_image_13.url;
        const vsImage14 = isEmpty(orderVersion.vs_image_14)
            ? ''
            : orderVersion.vs_image_14.url;
        const vsImage15 = isEmpty(orderVersion.vs_image_15)
            ? ''
            : orderVersion.vs_image_15.url;

        // splint
        const splintImage1 = isEmpty(orderVersion.splint_image_1)
            ? ''
            : orderVersion.splint_image_1.url;
        const splintImage2 = isEmpty(orderVersion.splint_image_2)
            ? ''
            : orderVersion.splint_image_2.url;
        const splintImage3 = isEmpty(orderVersion.splint_image_3)
            ? ''
            : orderVersion.splint_image_3.url;
        const splintImage4 = isEmpty(orderVersion.splint_image_4)
            ? ''
            : orderVersion.splint_image_4.url;
        const splintImage5 = isEmpty(orderVersion.splint_image_5)
            ? ''
            : orderVersion.splint_image_5.url;
        const splintImage6 = isEmpty(orderVersion.splint_image_6)
            ? ''
            : orderVersion.splint_image_6.url;
        const splintImage7 = isEmpty(orderVersion.splint_image_7)
            ? ''
            : orderVersion.splint_image_7.url;
        const splintImage8 = isEmpty(orderVersion.splint_image_8)
            ? ''
            : orderVersion.splint_image_8.url;
        const splintImage9 = isEmpty(orderVersion.splint_image_9)
            ? ''
            : orderVersion.splint_image_9.url;
        const splintImage10 = isEmpty(orderVersion.splint_image_10)
            ? ''
            : orderVersion.splint_image_10.url;

        const minivintsImages = [
            {
                original: minivintsImage1,
                thumbnail: minivintsImage1,
                title: orderVersion.minivints_label_1,
                index: 0
            },
            {
                original: minivintsImage2,
                thumbnail: minivintsImage2,
                title: orderVersion.minivints_label_2,
                index: 1
            },
            {
                original: minivintsImage3,
                thumbnail: minivintsImage3,
                title: orderVersion.minivints_label_3,
                index: 2
            },
            {
                original: minivintsImage4,
                thumbnail: minivintsImage4,
                title: orderVersion.minivints_label_4,
                index: 3
            },
            {
                original: minivintsImage5,
                thumbnail: minivintsImage5,
                title: orderVersion.minivints_label_5,
                index: 4
            },
            {
                original: minivintsImage6,
                thumbnail: minivintsImage6,
                title: orderVersion.minivints_label_6,
                index: 5
            },
            {
                original: minivintsImage7,
                thumbnail: minivintsImage7,
                title: orderVersion.minivints_label_7,
                index: 6
            },
            {
                original: minivintsImage8,
                thumbnail: minivintsImage8,
                title: orderVersion.minivints_label_8,
                index: 7
            },
            {
                original: minivintsImage9,
                thumbnail: minivintsImage9,
                title: orderVersion.minivints_label_9,
                index: 8
            },
            {
                original: minivintsImage10,
                thumbnail: minivintsImage10,
                title: orderVersion.minivints_label_10,
                index: 9
            }
        ];

        const templateImages = [
            {
                original: templateImage1,
                thumbnail: templateImage1,
                title: orderVersion.template_label_1,
                index: 0
            },
            {
                original: templateImage2,
                thumbnail: templateImage2,
                title: orderVersion.template_label_2,
                index: 1
            },
            {
                original: templateImage3,
                thumbnail: templateImage3,
                title: orderVersion.template_label_3,
                index: 2
            },
            {
                original: templateImage4,
                thumbnail: templateImage4,
                title: orderVersion.template_label_4,
                index: 3
            },
            {
                original: templateImage5,
                thumbnail: templateImage5,
                title: orderVersion.template_label_5,
                index: 4
            },
            {
                original: templateImage6,
                thumbnail: templateImage6,
                title: orderVersion.template_label_6,
                index: 5
            },
            {
                original: templateImage7,
                thumbnail: templateImage7,
                title: orderVersion.template_label_7,
                index: 6
            },
            {
                original: templateImage8,
                thumbnail: templateImage8,
                title: orderVersion.template_label_8,
                index: 7
            },
            {
                original: templateImage9,
                thumbnail: templateImage9,
                title: orderVersion.template_label_9,
                index: 8
            },
            {
                original: templateImage10,
                thumbnail: templateImage10,
                title: orderVersion.template_label_10,
                index: 9
            }
        ];

        const toolImages = [
            {
                original: toolImage1,
                thumbnail: toolImage1,
                title: orderVersion.tool_label_1,
                index: 0
            },
            {
                original: toolImage2,
                thumbnail: toolImage2,
                title: orderVersion.tool_label_2,
                index: 1
            },
            {
                original: toolImage3,
                thumbnail: toolImage3,
                title: orderVersion.tool_label_3,
                index: 2
            },
            {
                original: toolImage4,
                thumbnail: toolImage4,
                title: orderVersion.tool_label_4,
                index: 3
            },
            {
                original: toolImage5,
                thumbnail: toolImage5,
                title: orderVersion.tool_label_5,
                index: 4
            },
            {
                original: toolImage6,
                thumbnail: toolImage6,
                title: orderVersion.tool_label_6,
                index: 5
            },
            {
                original: toolImage7,
                thumbnail: toolImage7,
                title: orderVersion.tool_label_7,
                index: 6
            },
            {
                original: toolImage8,
                thumbnail: toolImage8,
                title: orderVersion.tool_label_8,
                index: 7
            },
            {
                original: toolImage9,
                thumbnail: toolImage9,
                title: orderVersion.tool_label_9,
                index: 8
            },
            {
                original: toolImage10,
                thumbnail: toolImage10,
                title: orderVersion.tool_label_10,
                index: 9
            }
        ];

        const tool2dImages = [
            {
                original: tool2dImage1,
                thumbnail: tool2dImage1,
                title: orderVersion.tool_2d_label_1,
                index: 0
            },
            {
                original: tool2dImage2,
                thumbnail: tool2dImage2,
                title: orderVersion.tool_2d_label_2,
                index: 1
            },
            {
                original: tool2dImage3,
                thumbnail: tool2dImage3,
                title: orderVersion.tool_2d_label_3,
                index: 2
            },
            {
                original: tool2dImage4,
                thumbnail: tool2dImage4,
                title: orderVersion.tool_2d_label_4,
                index: 3
            },
            {
                original: tool2dImage5,
                thumbnail: tool2dImage5,
                title: orderVersion.tool_2d_label_5,
                index: 4
            },
            {
                original: tool2dImage6,
                thumbnail: tool2dImage6,
                title: orderVersion.tool_2d_label_6,
                index: 5
            },
            {
                original: tool2dImage7,
                thumbnail: tool2dImage7,
                title: orderVersion.tool_2d_label_7,
                index: 6
            },
            {
                original: tool2dImage8,
                thumbnail: tool2dImage8,
                title: orderVersion.tool_2d_label_8,
                index: 7
            },
            {
                original: tool2dImage9,
                thumbnail: tool2dImage9,
                title: orderVersion.tool_2d_label_9,
                index: 8
            },
            {
                original: tool2dImage10,
                thumbnail: tool2dImage10,
                title: orderVersion.tool_2d_label_10,
                index: 9
            }
        ];

        const vsImages = [
            {
                original: vsImage1,
                thumbnail: vsImage1,
                title: orderVersion.vs_label_1,
                index: 0
            },
            {
                original: vsImage2,
                thumbnail: vsImage2,
                title: orderVersion.vs_label_2,
                index: 1
            },
            {
                original: vsImage3,
                thumbnail: vsImage3,
                title: orderVersion.vs_label_3,
                index: 2
            },
            {
                original: vsImage4,
                thumbnail: vsImage4,
                title: orderVersion.vs_label_4,
                index: 3
            },
            {
                original: vsImage5,
                thumbnail: vsImage5,
                title: orderVersion.vs_label_5,
                index: 4
            },
            {
                original: vsImage6,
                thumbnail: vsImage6,
                title: orderVersion.vs_label_6,
                index: 5
            },
            {
                original: vsImage7,
                thumbnail: vsImage7,
                title: orderVersion.vs_label_7,
                index: 6
            },
            {
                original: vsImage8,
                thumbnail: vsImage8,
                title: orderVersion.vs_label_8,
                index: 7
            },
            {
                original: vsImage9,
                thumbnail: vsImage9,
                title: orderVersion.vs_label_9,
                index: 8
            },
            {
                original: vsImage10,
                thumbnail: vsImage10,
                title: orderVersion.vs_label_10,
                index: 9
            },
            {
                original: vsImage11,
                thumbnail: vsImage11,
                title: orderVersion.vs_label_11,
                index: 10
            },
            {
                original: vsImage12,
                thumbnail: vsImage12,
                title: orderVersion.vs_label_12,
                index: 11
            },
            {
                original: vsImage13,
                thumbnail: vsImage13,
                title: orderVersion.vs_label_13,
                index: 12
            },
            {
                original: vsImage14,
                thumbnail: vsImage14,
                title: orderVersion.vs_label_14,
                index: 13
            },
            {
                original: vsImage15,
                thumbnail: vsImage15,
                title: orderVersion.vs_label_15,
                index: 14
            }
        ];

        const splintImages = [
            {
                original: splintImage1,
                thumbnail: splintImage1,
                title: orderVersion.splint_label_1,
                index: 0
            },
            {
                original: splintImage2,
                thumbnail: splintImage2,
                title: orderVersion.splint_label_2,
                index: 1
            },
            {
                original: splintImage3,
                thumbnail: splintImage3,
                title: orderVersion.splint_label_3,
                index: 2
            },
            {
                original: splintImage4,
                thumbnail: splintImage4,
                title: orderVersion.splint_label_4,
                index: 3
            },
            {
                original: splintImage5,
                thumbnail: splintImage5,
                title: orderVersion.splint_label_5,
                index: 4
            },
            {
                original: splintImage6,
                thumbnail: splintImage6,
                title: orderVersion.splint_label_6,
                index: 5
            },
            {
                original: splintImage7,
                thumbnail: splintImage7,
                title: orderVersion.splint_label_7,
                index: 6
            },
            {
                original: splintImage8,
                thumbnail: splintImage8,
                title: orderVersion.splint_label_8,
                index: 7
            },
            {
                original: splintImage9,
                thumbnail: splintImage9,
                title: orderVersion.splint_label_9,
                index: 8
            },
            {
                original: splintImage10,
                thumbnail: splintImage10,
                title: orderVersion.splint_label_10,
                index: 9
            }
        ];

        const images = [
            {
                original: img1,
                thumbnail: img1,
                title: orderVersion.label_1,
                index: 0
            },
            {
                original: img2,
                thumbnail: img2,
                title: orderVersion.label_2,
                index: 1
            },
            {
                original: img3,
                thumbnail: img3,
                title: orderVersion.label_3,
                index: 2
            },
            {
                original: img4,
                thumbnail: img4,
                title: orderVersion.label_4,
                index: 3
            },
            {
                original: img5,
                thumbnail: img5,
                title: orderVersion.label_5,
                index: 4
            }
        ];

        const options = [
            {
                label: texts.equipment.value,
                value: 'tool',
                disabled: stl3 === ''
            },
            {
                label: texts.template.value,
                value: 'template',
                disabled: stl4 === ''
            },
            {
                label: texts.splint.value,
                value: 'splint',
                disabled: stl20 === ''
            },
            // { label: texts.equipment.value, value: 'tool' },
            {
                label: isSlicesAvailable
                    ? texts.attachments.value
                    : texts.braces.value,
                value: 'braces',
                disabled: stl18 === '' && stl19 === '' && !isSlicesAvailable
            },
            // { label: texts.template.value, value: 'template' },
            { label: texts.none.value, value: 'none' }
        ];

        const getDefaultSelectedModel = (
            tool: string,
            template: string,
            bracesUpper: string,
            bracesLower: string,
            splint: string
        ) => {
            let result = 'none';
            if (tool !== '') {
                result = 'tool';
            } else if (template !== '') {
                result = 'template';
            } else if (bracesUpper !== '' || bracesLower !== '') {
                result = 'braces';
            } else if (splint !== '') {
                result = 'splint';
            }

            return result;
        };

        // const modeValue = shape.mode;
        let stlViewer;
        if (
            stl1 !== '' ||
            stl2 !== '' ||
            stl3 !== '' ||
            stl4 !== '' ||
            stl5 !== '' ||
            stl6 !== '' ||
            stl18 !== '' ||
            stl19 !== '' ||
            stl20 !== '' ||
            isSlicesAvailable
        ) {
            stlViewer = (
                <RowStyled gutter={16}>
                    <Col md={24} sm={24}>
                        <RowStyled
                            gutter={16}
                            style={{ padding: '32px 0 16px' }}
                        >
                            <Col md={24} sm={24} xs={24}>
                                <div
                                    style={{
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        minWidth: '515px'
                                    }}
                                >
                                    <ParamsTitle>
                                        {texts['order.3d.object'].value}:
                                    </ParamsTitle>
                                    <Radio.Group
                                        style={{ maxWidth: 300 }}
                                        options={options}
                                        onChange={onChangeMode}
                                        defaultValue={getDefaultSelectedModel(
                                            stl3,
                                            stl4,
                                            stl18,
                                            stl19,
                                            stl20
                                        )}
                                        value={shape.mode}
                                    />
                                </div>
                                <ModelUpperPanel>
                                    <ParamsTitle
                                        style={{ textAlign: 'center' }}
                                    >
                                        {texts.model.value}:
                                    </ParamsTitle>
                                    <Button
                                        disabled={
                                            stl1 === '' &&
                                            stl7 === '' &&
                                            !isSlicesAvailable
                                        }
                                        style={{ height: 64, marginLeft: 28 }}
                                        type={
                                            shape.upper ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelUpper}
                                    >
                                        <UpperJawIcon
                                            style={{
                                                color: shape.upper
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.upper_jaw.value}</div>
                                    </Button>
                                </ModelUpperPanel>
                                <div style={{ marginTop: 4 }}>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            minWidth: '515px'
                                        }}
                                    >
                                        <div
                                            style={{
                                                display: 'inline-block'
                                            }}
                                        >
                                            <ParamsTitle>
                                                {
                                                    texts['order.3d.parameters']
                                                        .value
                                                }
                                                :
                                            </ParamsTitle>
                                            <div style={{ marginTop: 10 }}>
                                                <Checkbox
                                                    disabled={stl2 === ''}
                                                    checked={
                                                        stl2 === ''
                                                            ? false
                                                            : shape.minivintsVisible
                                                    }
                                                    onChange={onChangeMinivints}
                                                >
                                                    {texts.minivints.value}
                                                </Checkbox>
                                                <Checkbox
                                                    disabled={stl6 === ''}
                                                    checked={
                                                        stl6 === ''
                                                            ? false
                                                            : shape.extraVisible
                                                    }
                                                    onChange={onChangeExtra}
                                                >
                                                    {texts.addition.value}
                                                </Checkbox>
                                            </div>
                                            <div>
                                                <Checkbox
                                                    onChange={onChangeBefore}
                                                    checked={shape.before}
                                                    style={{ width: 111 }}
                                                    disabled={isSlicesAvailable}
                                                >
                                                    {texts.before.value}
                                                </Checkbox>
                                                <Checkbox
                                                    disabled={
                                                        stl7 === '' &&
                                                        stl9 === '' &&
                                                        stl11 === '' &&
                                                        stl13 === '' &&
                                                        stl19 === ''
                                                    }
                                                    onChange={onChangeAfter}
                                                    checked={shape.after}
                                                >
                                                    {texts.after.value}
                                                </Checkbox>
                                            </div>
                                        </div>
                                        <MaxillaryUpperPanel
                                            style={{
                                                display: 'inline-block',
                                                verticalAlign: 'top',
                                                width: 180
                                            }}
                                        >
                                            <ParamsTitle
                                                style={{
                                                    display:
                                                        stl10 === '' &&
                                                        stl11 === '' &&
                                                        !isSlicesAvailable &&
                                                        stl12 === '' &&
                                                        stl13 === ''
                                                            ? 'none'
                                                            : 'block'
                                                }}
                                            >
                                                {
                                                    texts[
                                                        'stl.maxillary.opacity'
                                                    ].value
                                                }
                                                :
                                            </ParamsTitle>
                                            <Slider
                                                marks={{ 0: '0', 100: '100' }}
                                                style={{
                                                    display:
                                                        stl10 === '' &&
                                                        stl11 === '' &&
                                                        !isSlicesAvailable &&
                                                        stl12 === '' &&
                                                        stl13 === ''
                                                            ? 'none'
                                                            : 'block'
                                                }}
                                                step={5}
                                                value={shape.maxillaryOpacity}
                                                onChange={(value: any) =>
                                                    onChangeOpacity(
                                                        'maxillary',
                                                        value
                                                    )
                                                }
                                            />
                                        </MaxillaryUpperPanel>
                                    </div>
                                    <ModelLowerButon
                                        disabled={
                                            stl8 === '' &&
                                            stl9 === '' &&
                                            !isSlicesAvailable
                                        }
                                        type={
                                            shape.lower ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelLower}
                                    >
                                        <LowerJawIcon
                                            style={{
                                                color: shape.lower
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.lower_jaw.value}</div>
                                    </ModelLowerButon>
                                </div>
                                <ModelBottomPanel>
                                    <MaxillaryBottomPanel
                                        style={{
                                            display: 'block',
                                            verticalAlign: 'top',
                                            width: 180
                                        }}
                                    >
                                        <ParamsTitle
                                            style={{
                                                display:
                                                    stl10 === '' &&
                                                    stl11 === '' &&
                                                    stl12 === '' &&
                                                    !isSlicesAvailable &&
                                                    stl13 === ''
                                                        ? 'none'
                                                        : 'block'
                                            }}
                                        >
                                            {
                                                texts['stl.maxillary.opacity']
                                                    .value
                                            }
                                            :
                                        </ParamsTitle>
                                        <Slider
                                            marks={{ 0: '0', 100: '100' }}
                                            style={{
                                                display:
                                                    stl10 === '' &&
                                                    stl11 === '' &&
                                                    stl12 === '' &&
                                                    !isSlicesAvailable &&
                                                    stl13 === ''
                                                        ? 'none'
                                                        : 'block'
                                            }}
                                            step={5}
                                            value={shape.maxillaryOpacity}
                                            onChange={(value: any) =>
                                                onChangeOpacity(
                                                    'maxillary',
                                                    value
                                                )
                                            }
                                        />
                                    </MaxillaryBottomPanel>
                                    <ParamsTitle>
                                        {texts.model.value}:
                                    </ParamsTitle>
                                    <Button
                                        disabled={
                                            stl1 === '' &&
                                            stl7 === '' &&
                                            !isSlicesAvailable
                                        }
                                        style={{ height: 64 }}
                                        type={
                                            shape.upper ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelUpper}
                                    >
                                        <UpperJawIcon
                                            style={{
                                                color: shape.upper
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.upper_jaw.value}</div>
                                    </Button>
                                    <Button
                                        style={{
                                            height: 64,
                                            display: 'inline-block',
                                            marginLeft: 16,
                                            verticalAlign: 'top'
                                        }}
                                        disabled={
                                            stl8 === '' &&
                                            stl9 === '' &&
                                            !isSlicesAvailable
                                        }
                                        type={
                                            shape.lower ? 'primary' : 'default'
                                        }
                                        onClick={onChangeModelLower}
                                    >
                                        <LowerJawIcon
                                            style={{
                                                color: shape.lower
                                                    ? '#fff'
                                                    : '#000000a6'
                                            }}
                                        />
                                        <div>{texts.lower_jaw.value}</div>
                                    </Button>
                                </ModelBottomPanel>
                            </Col>
                            <Col
                                md={24}
                                sm={24}
                                xs={24}
                                // hidden={!isSlicesAvailable}
                            >
                                <div>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top',
                                            minWidth: '300px'
                                        }}
                                    >
                                        <ParamsTitle>{`${texts['view.title'].value}:`}</ParamsTitle>
                                        <Button.Group size="small">
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        Math.PI / 2,
                                                        Math.PI,
                                                        Math.PI
                                                    ]);
                                                }}
                                            >
                                                {texts['view.top'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        0,
                                                        Math.PI / 2,
                                                        0
                                                    ]);
                                                }}
                                            >
                                                {texts['view.right'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([0, 0, 0]);
                                                }}
                                            >
                                                {texts['view.front'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        0,
                                                        -Math.PI / 2,
                                                        0
                                                    ]);
                                                }}
                                            >
                                                {texts['view.left'].value}
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setSceneView([
                                                        -Math.PI / 2,
                                                        Math.PI,
                                                        Math.PI
                                                    ]);
                                                }}
                                            >
                                                {texts['view.bottom'].value}
                                            </Button>
                                        </Button.Group>
                                    </div>
                                    <div
                                        style={{
                                            display: 'inline-block',
                                            verticalAlign: 'top'
                                        }}
                                    >
                                        <ParamsTitle>{`${texts['view.background'].value}:`}</ParamsTitle>
                                        <ThemeSwitch
                                            style={{
                                                background: '#787878'
                                            }}
                                            checkedChildren="🌜"
                                            unCheckedChildren="🌞"
                                            onChange={(value: boolean) => {
                                                setTheme(
                                                    value ? 'dark' : 'light'
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={24} sm={24} xs={24}>
                                <OrderStlViewSettings
                                    hideButtons={false}
                                    texts={texts}
                                    header={texts['menu.settings'].value}
                                    modelColor={
                                        stlSettings
                                            ? stlSettings.model_color
                                            : '#e6bf8b'
                                    }
                                    modelAfterColor={shape.modelAfterColor}
                                    modelShineness={shape.modelShineness}
                                    modelAfterShineness={
                                        shape.modelAfterShineness
                                    }
                                    templateColor={
                                        stlSettings
                                            ? stlSettings.template_color
                                            : '#e6e1d6'
                                    }
                                    templateShineness={shape.templateShineness}
                                    minivintsColor={
                                        stlSettings
                                            ? stlSettings.minivints_color
                                            : '#009a63'
                                    }
                                    minivintsShineness={
                                        shape.minivintsShineness
                                    }
                                    toolColor={
                                        stlSettings
                                            ? stlSettings.tool_color
                                            : '#ffffff'
                                    }
                                    toolShineness={shape.toolShineness}
                                    trayColor={
                                        stlSettings
                                            ? stlSettings.tray_color
                                            : '#ffffff'
                                    }
                                    extraColor={
                                        stlSettings
                                            ? stlSettings.extraColor
                                                ? stlSettings.extraColor
                                                : '#696d6c'
                                            : '#696d6c'
                                    }
                                    extraOpacity={shape.extraOpacity}
                                    trayShineness={shape.trayShineness}
                                    trayOpacity={shape.trayOpacity}
                                    splintColor={
                                        stlSettings
                                            ? stlSettings.splint_color
                                                ? stlSettings.splint_color
                                                : '#aeccdc'
                                            : '#aeccdc'
                                    }
                                    splintShineness={shape.splintShineness}
                                    splintOpacity={shape.splintOpacity}
                                    maxillaryColor={
                                        stlSettings
                                            ? stlSettings.maxillary_color
                                                ? stlSettings.maxillary_color
                                                : '#e8a0a0'
                                            : '#e8a0a0'
                                    }
                                    maxillaryShineness={
                                        shape.maxillaryShineness
                                    }
                                    onChangeColor={onChangeColor}
                                    onDefault={onDefault}
                                    onChangeShineness={onChangeShineness}
                                    onChangeOpacity={onChangeOpacity}
                                    onSaveSettings={() => {
                                        dispatch(
                                            onSaveStlSettings(
                                                shape.modelColor,
                                                shape.modelAfterColor,
                                                shape.minivintsColor,
                                                shape.templateColor,
                                                shape.toolColor,
                                                shape.trayColor,
                                                shape.modelShineness,
                                                shape.modelAfterShineness,
                                                shape.minivintsShineness,
                                                shape.templateShineness,
                                                shape.toolShineness,
                                                shape.trayShineness,
                                                shape.trayOpacity,
                                                shape.splintColor,
                                                shape.splintShineness,
                                                shape.splintOpacity,
                                                shape.maxillaryColor,
                                                shape.maxillaryShineness
                                            )
                                        );
                                        message.success(
                                            texts['stl.settings.applied'].value
                                        );
                                    }}
                                />
                            </Col>
                        </RowStyled>
                        <Shape
                            theme={theme}
                            before={shape.before}
                            after={shape.after}
                            upper={shape.upper}
                            lower={shape.lower}
                            maxillaryUpper={stl10}
                            maxillaryUpperAfter={stl11}
                            maxillaryLower={stl12}
                            maxillaryLowerAfter={stl13}
                            maxillaryVisible={shape.maxillaryVisible}
                            maxillaryOpacity={shape.maxillaryOpacity}
                            maxillaryColor={shape.maxillaryColor}
                            maxillaryShineness={shape.maxillaryShineness}
                            model={stl1}
                            modelLower={stl8}
                            modelAfter={stl7}
                            modelLowerAfter={stl9}
                            modelVisible={shape.modelVisible}
                            modelColor={shape.modelColor}
                            modelShineness={shape.modelShineness}
                            modelAfterColor={shape.modelAfterColor}
                            modelAfterShineness={shape.modelAfterShineness}
                            extra={stl6}
                            extraVisible={shape.extraVisible}
                            extraColor={shape.extraColor}
                            extraShineness={shape.extraShineness}
                            extraOpacity={shape.extraOpacity}
                            minivints={stl2}
                            minivintsVisible={shape.minivintsVisible}
                            minivintsColor={shape.minivintsColor}
                            minivintsShineness={shape.minivintsShineness}
                            tool={stl3}
                            toolVisible={shape.toolVisible}
                            toolColor={shape.toolColor}
                            toolShineness={shape.toolShineness}
                            bracesUpper={stl18}
                            bracesLower={stl19}
                            bracesVisible={shape.bracesVisible}
                            bracesShineness={shape.bracesShineness}
                            bracesColor={shape.bracesColor}
                            template={stl4}
                            templateVisible={shape.templateVisible}
                            templateColor={shape.templateColor}
                            templateShineness={shape.templateShineness}
                            tray={stl5}
                            trayVisible={shape.trayVisible}
                            trayColor={shape.trayColor}
                            trayShineness={shape.trayShineness}
                            trayOpacity={shape.trayOpacity}
                            splint={stl20}
                            splintVisible={shape.splintVisible}
                            splintColor={shape.splintColor}
                            splintShineness={shape.splintShineness}
                            splintOpacity={shape.splintOpacity}
                            bordersVisible={shape.bordersVisible}
                            slices={slices}
                            sliceIndex={`${sliceIndex}`}
                            sceneView={sceneView}
                            showOverLayer={showOverLayer}
                            onCompletelyLoaded={() => {
                                setSlicesCompletelyLoaded(true);
                            }}
                        />
                        <SliceSlider
                            // value={sliceIndex}
                            disabled={!slicesCompletelyLoaded}
                            hidden={!isSlicesAvailable}
                            slices={slices}
                            showOverLayer={showOverLayer}
                            onChange={(sliderValue: any) => {
                                setSliceIndex(sliderValue);
                            }}
                            onShowOverLayer={(showLayerValue: any) => {
                                setShowOverLayer(showLayerValue);
                            }}
                        />
                    </Col>
                </RowStyled>
            );
        }

        const operations = (
            <ActionPanel>
                <Dropdown
                    trigger={['click']}
                    overlay={
                        <Menu>
                            <Menu.Item key="1">
                                {/* https://pumpalab.ru/ankets/zakaz-dop-aliners/?utm_source=button&utm_medium=direct&utm_campaign=dop_aligners */}

                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://pumpalab.ru/ankets/zakaz-dop-aliners/?utm_source=button&utm_medium=direct&utm_campaign=dop_aligners"
                                >
                                    {
                                        texts[
                                            'aligners.additional.info.addition'
                                        ].value
                                    }
                                </a>
                            </Menu.Item>
                            <Menu.Item key="2">
                                <a
                                    target="_blank"
                                    rel="noreferrer"
                                    href="https://pumpalab.ru/ankets/zakaz-na-sravnenie/?utm_source=button&utm_medium=direct&utm_campaign=na-sravnenie"
                                >
                                    {
                                        texts[
                                            'aligners.additional.info.compare'
                                        ].value
                                    }
                                </a>
                            </Menu.Item>
                        </Menu>
                    }
                >
                    <ButtonAction
                        size="small"
                        hidden={
                            order.status_aligners === 'NONE' ||
                            !order.status_aligners
                        }
                    >
                        {texts['aligners.additional.info'].value}
                        <Icon type="down" />
                    </ButtonAction>
                </Dropdown>
                <ButtonAction
                    onClick={() => onCopy(id, revision, texts, order)}
                    icon="share-alt"
                    size="small"
                >
                    {texts.share.value}
                </ButtonAction>
            </ActionPanel>
        );

        const onApproveOrderStage = (
            oCurrent: any,
            obj: any,
            texts: any,
            final: boolean
        ) => {
            // не корректно после подтверждения не сохраняется
            // наверное формат бэкапа отличается от формата который нужен
            let { backup: o } = oCurrent;
            let image1 = o.image_1;
            let image2 = o.image_2;
            let image3 = o.image_3;
            let image4 = o.image_4;
            let image5 = o.image_5;
            let mi1 = o.minivints_image_1;
            let mi2 = o.minivints_image_2;
            let mi3 = o.minivints_image_3;
            let mi4 = o.minivints_image_4;
            let mi5 = o.minivints_image_5;
            let mi6 = o.minivints_image_6;
            let mi7 = o.minivints_image_7;
            let mi8 = o.minivints_image_8;
            let mi9 = o.minivints_image_9;
            let mi10 = o.minivints_image_10;
            let ti1 = o.template_image_1;
            let ti2 = o.template_image_2;
            let ti3 = o.template_image_3;
            let ti4 = o.template_image_4;
            let ti5 = o.template_image_5;
            let ti6 = o.template_image_6;
            let ti7 = o.template_image_7;
            let ti8 = o.template_image_8;
            let ti9 = o.template_image_9;
            let ti10 = o.template_image_10;
            let toi1 = o.tool_image_1;
            let toi2 = o.tool_image_2;
            let toi3 = o.tool_image_3;
            let toi4 = o.tool_image_4;
            let toi5 = o.tool_image_5;
            let toi6 = o.tool_image_6;
            let toi7 = o.tool_image_7;
            let toi8 = o.tool_image_8;
            let toi9 = o.tool_image_9;
            let toi10 = o.tool_image_10;
            // tool_2d_image
            let t2di1 = o.tool_2d_image_1;
            let t2di2 = o.tool_2d_image_2;
            let t2di3 = o.tool_2d_image_3;
            let t2di4 = o.tool_2d_image_4;
            let t2di5 = o.tool_2d_image_5;
            let t2di6 = o.tool_2d_image_6;
            let t2di7 = o.tool_2d_image_7;
            let t2di8 = o.tool_2d_image_8;
            let t2di9 = o.tool_2d_image_9;
            let t2di10 = o.tool_2d_image_10;
            // vs setup
            let vi1 = o.vs_image_1;
            let vi2 = o.vs_image_2;
            let vi3 = o.vs_image_3;
            let vi4 = o.vs_image_4;
            let vi5 = o.vs_image_5;
            let vi6 = o.vs_image_6;
            let vi7 = o.vs_image_7;
            let vi8 = o.vs_image_8;
            let vi9 = o.vs_image_9;
            let vi10 = o.vs_image_10;
            let vi11 = o.vs_image_11;
            let vi12 = o.vs_image_12;
            let vi13 = o.vs_image_13;
            let vi14 = o.vs_image_14;
            let vi15 = o.vs_image_15;
            // splint setup
            let si1 = o.splint_image_1;
            let si2 = o.splint_image_2;
            let si3 = o.splint_image_3;
            let si4 = o.splint_image_4;
            let si5 = o.splint_image_5;
            let si6 = o.splint_image_6;
            let si7 = o.splint_image_7;
            let si8 = o.splint_image_8;
            let si9 = o.splint_image_9;
            let si10 = o.splint_image_10;
            // stls
            let s1 = o.istl_1;
            let s2 = o.istl_2;
            let s3 = o.istl_3;
            let s4 = o.istl_4;
            let s5 = o.istl_5;
            let s6 = o.istl_6;
            let s7 = o.istl_7;
            let s8 = o.istl_8;
            let s9 = o.istl_9;
            let s10 = o.istl_10;
            let s11 = o.istl_11;
            let s12 = o.istl_12;
            let s13 = o.istl_13;
            let s14 = o.istl_14;
            let s15 = o.istl_15;
            let s16 = o.istl_16;
            let s17 = o.istl_17;
            let s18 = o.istl_18;
            let s19 = o.istl_19;
            let s20 = o.istl_20;
            // gifs
            let g = o.gif;
            let g2d = o.gif2d;
            let gs = o.gif_splint;
            let z = o.zip;
            let v = o.video;
            let w = o.workorder;
            if (isEmpty(o)) {
                o = oCurrent;
                image1 = isEmpty(o.image_1) ? undefined : o.image_1.id;
                image2 = isEmpty(o.image_2) ? undefined : o.image_2.id;
                image3 = isEmpty(o.image_3) ? undefined : o.image_3.id;
                image4 = isEmpty(o.image_4) ? undefined : o.image_4.id;
                image5 = isEmpty(o.image_5) ? undefined : o.image_5.id;
                mi1 = isEmpty(o.minivints_image_1)
                    ? undefined
                    : o.minivints_image_1.id;
                mi2 = isEmpty(o.minivints_image_2)
                    ? undefined
                    : o.minivints_image_2.id;
                mi3 = isEmpty(o.minivints_image_3)
                    ? undefined
                    : o.minivints_image_3.id;
                mi4 = isEmpty(o.minivints_image_4)
                    ? undefined
                    : o.minivints_image_4.id;
                mi5 = isEmpty(o.minivints_image_5)
                    ? undefined
                    : o.minivints_image_5.id;
                mi6 = isEmpty(o.minivints_image_6)
                    ? undefined
                    : o.minivints_image_6.id;
                mi7 = isEmpty(o.minivints_image_7)
                    ? undefined
                    : o.minivints_image_7.id;
                mi8 = isEmpty(o.minivints_image_8)
                    ? undefined
                    : o.minivints_image_8.id;
                mi9 = isEmpty(o.minivints_image_9)
                    ? undefined
                    : o.minivints_image_9.id;
                mi10 = isEmpty(o.minivints_image_10)
                    ? undefined
                    : o.minivints_image_10.id;
                ti1 = isEmpty(o.template_image_1)
                    ? undefined
                    : o.template_image_1.id;
                ti2 = isEmpty(o.template_image_2)
                    ? undefined
                    : o.template_image_2.id;
                ti3 = isEmpty(o.template_image_3)
                    ? undefined
                    : o.template_image_3.id;
                ti4 = isEmpty(o.template_image_4)
                    ? undefined
                    : o.template_image_4.id;
                ti5 = isEmpty(o.template_image_5)
                    ? undefined
                    : o.template_image_5.id;
                ti6 = isEmpty(o.template_image_6)
                    ? undefined
                    : o.template_image_6.id;
                ti7 = isEmpty(o.template_image_7)
                    ? undefined
                    : o.template_image_7.id;
                ti8 = isEmpty(o.template_image_8)
                    ? undefined
                    : o.template_image_8.id;
                ti9 = isEmpty(o.template_image_9)
                    ? undefined
                    : o.template_image_9.id;
                ti10 = isEmpty(o.template_image_10)
                    ? undefined
                    : o.template_image_10.id;
                toi1 = isEmpty(o.tool_image_1) ? undefined : o.tool_image_1.id;
                toi2 = isEmpty(o.tool_image_2) ? undefined : o.tool_image_2.id;
                toi3 = isEmpty(o.tool_image_3) ? undefined : o.tool_image_3.id;
                toi4 = isEmpty(o.tool_image_4) ? undefined : o.tool_image_4.id;
                toi5 = isEmpty(o.tool_image_5) ? undefined : o.tool_image_5.id;
                toi6 = isEmpty(o.tool_image_6) ? undefined : o.tool_image_6.id;
                toi7 = isEmpty(o.tool_image_7) ? undefined : o.tool_image_7.id;
                toi8 = isEmpty(o.tool_image_8) ? undefined : o.tool_image_8.id;
                toi9 = isEmpty(o.tool_image_9) ? undefined : o.tool_image_9.id;
                toi10 = isEmpty(o.tool_image_10)
                    ? undefined
                    : o.tool_image_10.id;
                // tool_2d_image
                t2di1 = isEmpty(o.tool_2d_image_1)
                    ? undefined
                    : o.tool_2d_image_1.id;
                t2di2 = isEmpty(o.tool_2d_image_2)
                    ? undefined
                    : o.tool_2d_image_2.id;
                t2di3 = isEmpty(o.tool_2d_image_3)
                    ? undefined
                    : o.tool_2d_image_3.id;
                t2di4 = isEmpty(o.tool_2d_image_4)
                    ? undefined
                    : o.tool_2d_image_4.id;
                t2di5 = isEmpty(o.tool_2d_image_5)
                    ? undefined
                    : o.tool_2d_image_5.id;
                t2di6 = isEmpty(o.tool_2d_image_6)
                    ? undefined
                    : o.tool_2d_image_6.id;
                t2di7 = isEmpty(o.tool_2d_image_7)
                    ? undefined
                    : o.tool_2d_image_7.id;
                t2di8 = isEmpty(o.tool_2d_image_8)
                    ? undefined
                    : o.tool_2d_image_8.id;
                t2di9 = isEmpty(o.tool_2d_image_9)
                    ? undefined
                    : o.tool_2d_image_9.id;
                t2di10 = isEmpty(o.tool_2d_image_10)
                    ? undefined
                    : o.tool_2d_image_10.id;
                // vs
                vi1 = isEmpty(o.vs_image_1) ? undefined : o.vs_image_1.id;
                vi2 = isEmpty(o.vs_image_2) ? undefined : o.vs_image_2.id;
                vi3 = isEmpty(o.vs_image_3) ? undefined : o.vs_image_3.id;
                vi4 = isEmpty(o.vs_image_4) ? undefined : o.vs_image_4.id;
                vi5 = isEmpty(o.vs_image_5) ? undefined : o.vs_image_5.id;
                vi6 = isEmpty(o.vs_image_6) ? undefined : o.vs_image_6.id;
                vi7 = isEmpty(o.vs_image_7) ? undefined : o.vs_image_7.id;
                vi8 = isEmpty(o.vs_image_8) ? undefined : o.vs_image_8.id;
                vi9 = isEmpty(o.vs_image_9) ? undefined : o.vs_image_9.id;
                vi10 = isEmpty(o.vs_image_10) ? undefined : o.vs_image_10.id;
                vi11 = isEmpty(o.vs_image_11) ? undefined : o.vs_image_11.id;
                vi12 = isEmpty(o.vs_image_12) ? undefined : o.vs_image_12.id;
                vi13 = isEmpty(o.vs_image_13) ? undefined : o.vs_image_13.id;
                vi14 = isEmpty(o.vs_image_14) ? undefined : o.vs_image_14.id;
                vi15 = isEmpty(o.vs_image_15) ? undefined : o.vs_image_15.id;
                // splint
                // vs
                si1 = isEmpty(o.splint_image_1)
                    ? undefined
                    : o.splint_image_1.id;
                si2 = isEmpty(o.splint_image_2)
                    ? undefined
                    : o.splint_image_2.id;
                si3 = isEmpty(o.splint_image_3)
                    ? undefined
                    : o.splint_image_3.id;
                si4 = isEmpty(o.splint_image_4)
                    ? undefined
                    : o.splint_image_4.id;
                si5 = isEmpty(o.splint_image_5)
                    ? undefined
                    : o.splint_image_5.id;
                si6 = isEmpty(o.splint_image_6)
                    ? undefined
                    : o.splint_image_6.id;
                si7 = isEmpty(o.splint_image_7)
                    ? undefined
                    : o.splint_image_7.id;
                si8 = isEmpty(o.splint_image_8)
                    ? undefined
                    : o.splint_image_8.id;
                si9 = isEmpty(o.splint_image_9)
                    ? undefined
                    : o.splint_image_9.id;
                si10 = isEmpty(o.splint_image_10)
                    ? undefined
                    : o.splint_image_10.id;
                // stl
                s1 = isEmpty(o.stl_1) ? undefined : o.stl_1.id;
                s2 = isEmpty(o.stl_2) ? undefined : o.stl_2.id;
                s3 = isEmpty(o.stl_3) ? undefined : o.stl_3.id;
                s4 = isEmpty(o.stl_4) ? undefined : o.stl_4.id;
                s5 = isEmpty(o.stl_5) ? undefined : o.stl_5.id;
                s6 = isEmpty(o.stl_6) ? undefined : o.stl_6.id;
                s7 = isEmpty(o.stl_7) ? undefined : o.stl_7.id;
                s8 = isEmpty(o.stl_8) ? undefined : o.stl_8.id;
                s9 = isEmpty(o.stl_9) ? undefined : o.stl_9.id;
                s10 = isEmpty(o.stl_10) ? undefined : o.stl_10.id;
                s11 = isEmpty(o.stl_11) ? undefined : o.stl_11.id;
                s12 = isEmpty(o.stl_12) ? undefined : o.stl_12.id;
                s13 = isEmpty(o.stl_13) ? undefined : o.stl_13.id;
                s14 = isEmpty(o.stl_14) ? undefined : o.stl_14.id;
                s15 = isEmpty(o.stl_15) ? undefined : o.stl_15.id;
                s16 = isEmpty(o.stl_16) ? undefined : o.stl_16.id;
                s17 = isEmpty(o.stl_17) ? undefined : o.stl_17.id;
                s18 = isEmpty(o.stl_18) ? undefined : o.stl_18.id;
                s19 = isEmpty(o.stl_19) ? undefined : o.stl_19.id;
                s20 = isEmpty(o.stl_20) ? undefined : o.stl_20.id;
                // gifs
                g = isEmpty(o.gif) ? undefined : o.gif.id;
                g2d = isEmpty(o.gif2d) ? undefined : o.gif2d.id;
                gs = isEmpty(o.gif_splint) ? undefined : o.gif_splint.id;
                z = isEmpty(o.zip) ? undefined : o.zip.id;
                v = isEmpty(o.video) ? undefined : o.video.id;
                w = isEmpty(o.workorder) ? undefined : o.workorder.id;
            }
            const data = {
                orderId: o.order,
                doctor: order.doctor,
                public: true,
                design_date: moment.utc().format(),
                status: o.status,
                status_minivints:
                    obj === 'minivints' ? 'APPROVED' : o.status_minivints,
                status_equipment:
                    obj === 'appliance' ? 'APPROVED' : o.status_equipment,
                status_template:
                    obj === 'template' ? 'APPROVED' : o.status_template,
                status_tray: o.status_tray,
                status_splint: obj === 'splint' ? 'APPROVED' : o.status_splint,
                status_braces: obj === 'braces' ? 'APPROVED' : o.status_braces,
                status_aligners:
                    obj === 'aligners' ? 'APPROVED' : o.status_aligners,
                status_equipment_2d:
                    obj === 'appliance2d' ? 'APPROVED' : o.status_equipment_2d,
                image1: isEmpty(image1) ? undefined : `${image1}`,
                image2: isEmpty(image2) ? undefined : `${image2}`,
                image3: isEmpty(image3) ? undefined : `${image3}`,
                image4: isEmpty(image4) ? undefined : `${image4}`,
                image5: isEmpty(image5) ? undefined : `${image5}`,
                // minivints
                minivintsImage1: mi1,
                minivintsImage2: mi2,
                minivintsImage3: mi3,
                minivintsImage4: mi4,
                minivintsImage5: mi5,
                minivintsImage6: mi6,
                minivintsImage7: mi7,
                minivintsImage8: mi8,
                minivintsImage9: mi9,
                minivintsImage10: mi10,
                // template
                templateImage1: ti1,
                templateImage2: ti2,
                templateImage3: ti3,
                templateImage4: ti4,
                templateImage5: ti5,
                templateImage6: ti6,
                templateImage7: ti7,
                templateImage8: ti8,
                templateImage9: ti9,
                templateImage10: ti10,
                // tool
                toolImage1: toi1,
                toolImage2: toi2,
                toolImage3: toi3,
                toolImage4: toi4,
                toolImage5: toi5,
                toolImage6: toi6,
                toolImage7: toi7,
                toolImage8: toi8,
                toolImage9: toi9,
                toolImage10: toi10,
                // tool 2d
                tool2dImage1: t2di1,
                tool2dImage2: t2di2,
                tool2dImage3: t2di3,
                tool2dImage4: t2di4,
                tool2dImage5: t2di5,
                tool2dImage6: t2di6,
                tool2dImage7: t2di7,
                tool2dImage8: t2di8,
                tool2dImage9: t2di9,
                tool2dImage10: t2di10,
                // virtual setup
                vsImage1: vi1,
                vsImage2: vi2,
                vsImage3: vi3,
                vsImage4: vi4,
                vsImage5: vi5,
                vsImage6: vi6,
                vsImage7: vi7,
                vsImage8: vi8,
                vsImage9: vi9,
                vsImage10: vi10,
                vsImage11: vi11,
                vsImage12: vi12,
                vsImage13: vi13,
                vsImage14: vi14,
                vsImage15: vi15,
                // splint
                splintImage1: si1,
                splintImage2: si2,
                splintImage3: si3,
                splintImage4: si4,
                splintImage5: si5,
                splintImage6: si6,
                splintImage7: si7,
                splintImage8: si8,
                splintImage9: si9,
                splintImage10: si10,
                // labels
                label1: isEmpty(o.label_1) ? '' : o.label_1,
                label2: isEmpty(o.label_2) ? '' : o.label_2,
                label3: isEmpty(o.label_3) ? '' : o.label_3,
                label4: isEmpty(o.label_4) ? '' : o.label_4,
                label5: isEmpty(o.label_5) ? '' : o.label_5,
                // minivints
                minivintsLabel1: isEmpty(o.minivints_label_1)
                    ? undefined
                    : o.minivints_label_1,
                minivintsLabel2: isEmpty(o.minivints_label_2)
                    ? undefined
                    : o.minivints_label_2,
                minivintsLabel3: isEmpty(o.minivints_label_3)
                    ? undefined
                    : o.minivints_label_3,
                minivintsLabel4: isEmpty(o.minivints_label_4)
                    ? undefined
                    : o.minivints_label_4,
                minivintsLabel5: isEmpty(o.minivints_label_5)
                    ? undefined
                    : o.minivints_label_5,
                minivintsLabel6: isEmpty(o.minivints_label_6)
                    ? undefined
                    : o.minivints_label_6,
                minivintsLabel7: isEmpty(o.minivints_label_7)
                    ? undefined
                    : o.minivints_label_7,
                minivintsLabel8: isEmpty(o.minivints_label_8)
                    ? undefined
                    : o.minivints_label_8,
                minivintsLabel9: isEmpty(o.minivints_label_9)
                    ? undefined
                    : o.minivints_label_9,
                minivintsLabel10: isEmpty(o.minivints_label_10)
                    ? undefined
                    : o.minivints_label_10,
                // template
                templateLabel1: isEmpty(o.template_label_1)
                    ? undefined
                    : o.template_label_1,
                templateLabel2: isEmpty(o.template_label_2)
                    ? undefined
                    : o.template_label_2,
                templateLabel3: isEmpty(o.template_label_3)
                    ? undefined
                    : o.template_label_3,
                templateLabel4: isEmpty(o.template_label_4)
                    ? undefined
                    : o.template_label_4,
                templateLabel5: isEmpty(o.template_label_5)
                    ? undefined
                    : o.template_label_5,
                templateLabel6: isEmpty(o.template_label_6)
                    ? undefined
                    : o.template_label_6,
                templateLabel7: isEmpty(o.template_label_7)
                    ? undefined
                    : o.template_label_7,
                templateLabel8: isEmpty(o.template_label_8)
                    ? undefined
                    : o.template_label_8,
                templateLabel9: isEmpty(o.template_label_9)
                    ? undefined
                    : o.template_label_9,
                templateLabel10: isEmpty(o.template_label_10)
                    ? undefined
                    : o.template_label_10,
                // tool
                toolLabel1: isEmpty(o.tool_label_1)
                    ? undefined
                    : o.tool_label_1,
                toolLabel2: isEmpty(o.tool_label_2)
                    ? undefined
                    : o.tool_label_2,
                toolLabel3: isEmpty(o.tool_label_3)
                    ? undefined
                    : o.tool_label_3,
                toolLabel4: isEmpty(o.tool_label_4)
                    ? undefined
                    : o.tool_label_4,
                toolLabel5: isEmpty(o.tool_label_5)
                    ? undefined
                    : o.tool_label_5,
                toolLabel6: isEmpty(o.tool_label_6)
                    ? undefined
                    : o.tool_label_6,
                toolLabel7: isEmpty(o.tool_label_7)
                    ? undefined
                    : o.tool_label_7,
                toolLabel8: isEmpty(o.tool_label_8)
                    ? undefined
                    : o.tool_label_8,
                toolLabel9: isEmpty(o.tool_label_9)
                    ? undefined
                    : o.tool_label_9,
                toolLabel10: isEmpty(o.tool_label_10)
                    ? undefined
                    : o.tool_label_10,
                // tool2d
                tool2dLabel1: isEmpty(o.tool_2d_label_1)
                    ? undefined
                    : o.tool_2d_label_1,
                tool2dLabel2: isEmpty(o.tool_2d_label_2)
                    ? undefined
                    : o.tool_2d_label_2,
                tool2dLabel3: isEmpty(o.tool_2d_label_3)
                    ? undefined
                    : o.tool_2d_label_3,
                tool2dLabel4: isEmpty(o.tool_2d_label_4)
                    ? undefined
                    : o.tool_2d_label_4,
                tool2dLabel5: isEmpty(o.tool_2d_label_5)
                    ? undefined
                    : o.tool_2d_label_5,
                tool2dLabel6: isEmpty(o.tool_2d_label_6)
                    ? undefined
                    : o.tool_2d_label_6,
                tool2dLabel7: isEmpty(o.tool_2d_label_7)
                    ? undefined
                    : o.tool_2d_label_7,
                tool2dLabel8: isEmpty(o.tool_2d_label_8)
                    ? undefined
                    : o.tool_2d_label_8,
                tool2dLabel9: isEmpty(o.tool_2d_label_9)
                    ? undefined
                    : o.tool_2d_label_9,
                tool2dLabel10: isEmpty(o.tool_2d_label_10)
                    ? undefined
                    : o.tool_2d_label_10,
                vsLabel1: isEmpty(o.vs_label_1) ? undefined : o.vs_label_1,
                vsLabel2: isEmpty(o.vs_label_2) ? undefined : o.vs_label_2,
                vsLabel3: isEmpty(o.vs_label_3) ? undefined : o.vs_label_3,
                vsLabel4: isEmpty(o.vs_label_4) ? undefined : o.vs_label_4,
                vsLabel5: isEmpty(o.vs_label_5) ? undefined : o.vs_label_5,
                vsLabel6: isEmpty(o.vs_label_6) ? undefined : o.vs_label_6,
                vsLabel7: isEmpty(o.vs_label_7) ? undefined : o.vs_label_7,
                vsLabel8: isEmpty(o.vs_label_8) ? undefined : o.vs_label_8,
                vsLabel9: isEmpty(o.vs_label_9) ? undefined : o.vs_label_9,
                vsLabel10: isEmpty(o.vs_label_10) ? undefined : o.vs_label_10,
                vsLabel11: isEmpty(o.vs_label_11) ? undefined : o.vs_label_11,
                vsLabel12: isEmpty(o.vs_label_12) ? undefined : o.vs_label_12,
                vsLabel13: isEmpty(o.vs_label_13) ? undefined : o.vs_label_13,
                vsLabel14: isEmpty(o.vs_label_14) ? undefined : o.vs_label_14,
                vsLabel15: isEmpty(o.vs_label_15) ? undefined : o.vs_label_15,
                // splint
                splintLabel1: isEmpty(o.splint_label_1)
                    ? undefined
                    : o.splint_label_1,
                splintLabel2: isEmpty(o.splint_label_2)
                    ? undefined
                    : o.splint_label_2,
                splintLabel3: isEmpty(o.splint_label_3)
                    ? undefined
                    : o.splint_label_3,
                splintLabel4: isEmpty(o.splint_label_4)
                    ? undefined
                    : o.splint_label_4,
                splintLabel5: isEmpty(o.splint_label_5)
                    ? undefined
                    : o.splint_label_5,
                splintLabel6: isEmpty(o.splint_label_6)
                    ? undefined
                    : o.splint_label_6,
                splintLabel7: isEmpty(o.splint_label_7)
                    ? undefined
                    : o.splint_label_7,
                splintLabel8: isEmpty(o.splint_label_8)
                    ? undefined
                    : o.splint_label_8,
                splintLabel9: isEmpty(o.splint_label_9)
                    ? undefined
                    : o.splint_label_9,
                splintLabel10: isEmpty(o.splint_label_10)
                    ? undefined
                    : o.splint_label_10,
                stl1: s1,
                stl2: s2,
                stl3: s3,
                stl4: s4,
                stl5: s5,
                stl6: s6,
                stl7: s7,
                stl8: s8,
                stl9: s9,
                stl10: s10,
                stl11: s11,
                stl12: s12,
                stl13: s13,
                stl14: s14,
                stl15: s15,
                stl16: s16,
                stl17: s17,
                stl18: s18,
                stl19: s19,
                stl20: s20,
                // gifs
                gif: g,
                gif2d: g2d,
                gif_splint: gs,
                zip: z,
                video: v,
                workorder: w,
                comment: texts[`approve.${obj}`].value,
                version: o.version
            };

            if (final) {
                data.status = 'READY';
                data.status_template =
                    data.status_template !== 'NONE' ? 'READY' : 'NONE';
                data.status_equipment =
                    data.status_equipment !== 'NONE' ? 'READY' : 'NONE';
                data.status_equipment_2d =
                    data.status_equipment_2d !== 'NONE' ? 'READY' : 'NONE';
                data.status_aligners =
                    data.status_aligners !== 'NONE' ? 'READY' : 'NONE';
                data.status_braces =
                    data.status_braces !== 'NONE' ? 'READY' : 'NONE';
                data.status_splint =
                    data.status_splint !== 'NONE' ? 'READY' : 'NONE';
            }
            dispatch(approveOrderStage(data));
        };

        content = (
            <>
                {/* <Head>
                    <title>{this.getTitle(order, texts)}</title>
                </Head> */}
                <Row gutter={16}>
                    <Col lg={6} md={24}>
                        <OrderCardParams
                            texts={texts}
                            scope="doctor"
                            order={order}
                            revision={revision}
                            orderVersion={orderVersion}
                            onApproveOrderStage={(o: any, s: any, t: boolean) =>
                                onApproveOrderStage(o, s, texts, t)
                            }
                            showTimeline
                        />
                    </Col>
                    <Col lg={18} md={24}>
                        <TabsContainer>
                            <Tabs
                                defaultActiveKey={activeTab}
                                tabBarExtraContent={operations}
                            >
                                <TabsBody
                                    tab={texts['order.details'].value}
                                    key="1"
                                >
                                    <ContentLayout>
                                        <ContentStyled>
                                            <AlignersInfo order={order} />
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        items={images}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.minivints
                                                                .value
                                                        }
                                                        items={minivintsImages}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.template.value
                                                        }
                                                        items={templateImages}
                                                        video={video}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.equipment
                                                                .value
                                                        }
                                                        items={toolImages}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.equipment2d
                                                                .value
                                                        }
                                                        items={tool2dImages}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.virtual_setup
                                                                .value
                                                        }
                                                        items={vsImages}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            <RowStyled gutter={16}>
                                                <Col md={24} sm={24}>
                                                    <OrderImageGallery
                                                        title={
                                                            texts.splint.value
                                                        }
                                                        items={splintImages}
                                                    />
                                                </Col>
                                            </RowStyled>
                                            {stlViewer}
                                            <Divider />
                                            {gif && (
                                                <OrderGifCard
                                                    title={
                                                        texts.equipment_check
                                                            .value
                                                    }
                                                    mode="3d"
                                                    orderId={idFromQuery}
                                                    revisionId={orderVersion.id}
                                                />
                                            )}
                                            {gif2d && (
                                                <OrderGifCard
                                                    title={
                                                        texts.equipment2d_check
                                                            .value
                                                    }
                                                    mode="2d"
                                                    orderId={idFromQuery}
                                                    revisionId={orderVersion.id}
                                                />
                                            )}
                                            {gif_splint && (
                                                <OrderGifCard
                                                    title={
                                                        texts.splint_check.value
                                                    }
                                                    mode="splint"
                                                    orderId={idFromQuery}
                                                    revisionId={orderVersion.id}
                                                />
                                            )}
                                            <Divider />
                                        </ContentStyled>
                                    </ContentLayout>
                                </TabsBody>
                                <TabsBody
                                    tab={texts['order.feedback'].value}
                                    key="2"
                                >
                                    <OrderComments
                                        texts={texts}
                                        orderId={id}
                                        revisionId={revision}
                                    />
                                </TabsBody>
                            </Tabs>
                        </TabsContainer>
                    </Col>
                </Row>
            </>
        );
    }

    if (profile && order) {
        if (profile.id !== order.doctor.id) {
            return <Result status="403" title="403" />;
        } else {
            return <>{content}</>;
        }
    } else {
        return <CenteredSpinner />;
    }
};
